import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
// import store from "../store";

Vue.use(VueRouter);
Vue.use(VueMeta);

function lazyView(view) {
  return () =>
    import(
      /* webpackChunkName: "lazy-view-[request]" */ `../views/${view}.vue`
    );
}

function lazyComponnentView(view) {
  return () =>
    import(
      /* webpackChunkName: "lazy-view-[request]" */ `../components/${view}.vue`
    );
}

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: lazyComponnentView("Login"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: lazyView("SignUp"),
  },
  {
    path: "/home",
    name: "Home",
    component: lazyComponnentView("Home"),
  },
  {
    path: "/mydairy",
    name: "MyDairy",
    component: lazyComponnentView("MyDairy"),
  },
  {
    path: "/mypost",
    name: "Creativity",
    component: lazyComponnentView("MyCreativity"),
  },
  {
    path: "/savedpost",
    name: "FavoritePosts",
    component: lazyComponnentView("BookmarkedPosts"),
  },
  {
    path: "/trendingPost",
    name: "TrendingPosts",
    component: lazyComponnentView("TrendingPosts"),
  },
  {
    path: "/trendingAuthors",
    name: "TrendingAuthors",
    component: lazyComponnentView("TrendingAuthors"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: lazyComponnentView("Notifications"),
  },
  {
    path: "/post/:id",
    name: "FullPost",
    component: lazyComponnentView("FullPost"),
  },
  {
    path: "/favorite-writers",
    name: "FollowList",
    component: lazyView("FollowList"),
  },
  {
    path: "/profile/:id",
    name: "Author",
    component: lazyComponnentView("AuthorCard"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: lazyView("ForgotPassword"),
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: lazyView("PasswordReset"),
  },
  {
    path: "/mydrafts",
    name: "MyDrafts",
    component: lazyComponnentView("MyDrafts"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: lazyView("Settings"),
  },
  {
    path: "/terms",
    name: "TermsAndCondition",
    component: lazyComponnentView("TermsAndCondition"),
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  hashbang: false,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "Login",
    "SignUp",
    "FullPost",
    "ForgotPassword",
    "PasswordReset",
    "Home",
    "TrendingPosts",
    "TermsAndCondition",
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
  // var hasPermission = store.getters.isUserAuthenticated;
  // if (to.name !== "Login" && !hasPermission) next({ name: "Login" });
  // else next();
});

export default router;
