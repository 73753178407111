<template>
  <div id="app">
    <v-app id="mirace">
      <div v-if="isUserLoaded">
        <NavBar id="navbar"></NavBar>
        <main class="page-content">
          <router-view></router-view>
        </main>
        <SideBar></SideBar>
        <CommonModal></CommonModal>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NavBar from "./components/NavBar.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "Nisharga",
    titleTemplate: "%s | Your way to the world of Nirvana",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Get unlimited content of free Stories | Articles | Blogs | Poems and much more. Its free, easy and enjoyable. Register today to access all of its feature",
      },
      {
        name: "og:description",
        content:
          "Get unlimited content of free Stories | Articles | Blogs | Poems and much more. Its free, easy and enjoyable. Register today to access all of its feature",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    NavBar,
    SideBar: () =>
      import(/* webpackPrefetch: true */ "./components/Sidebar.vue"),
    CommonModal: () =>
      import(/* webpackPrefetch: true */ "./components/modals/CommonModal.vue"),
  },

  data() {
    return {
      isUserLoaded: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),
  },

  async created() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.id && !this.userDetails?.id) {
      try {
        await this.getUserDetails({ userId: user.id });
      } catch (e) {
        console.error(e);
      } finally {
        this.isUserLoaded = true;
      }
    } else {
      this.isUserLoaded = true;
    }
  },

  mounted() {
    this.getNextArticle();
  },

  methods: {
    ...mapActions(["getUserDetails"]),

    getNextArticle() {
      window.onscroll = () => {
        // var navbar = document.getElementById("navbar");
        var trendingBox = document.getElementById("trending");
        // var sticky = navbar.offsetTop;

        // if (window.pageYOffset > sticky) {
        // navbar.classList.add("sticky-navbar");
        if (window.screen.width > 1023) {
          trendingBox && trendingBox.classList.add("sticky-tranding-box");
        }
        // } else {
        //   navbar.classList.remove("sticky-navbar");
        //   trendingBox && trendingBox.classList.remove("sticky-tranding-box");
        // }
      };
    },
  },
};
</script>
