import Vue from "vue";
import { numberFilter, timeFilterComment, ageFilter } from "./customFilters";

export function loadFilters() {
  Vue.filter("numberFilter", numberFilter);
  Vue.filter("timeFilterComment", timeFilterComment);
  Vue.filter("ageFilter", ageFilter);
}

export default { numberFilter, timeFilterComment, ageFilter };
