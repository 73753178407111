import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VBToggle } from "bootstrap-vue";
import { LayoutPlugin } from "bootstrap-vue";
import vuetify from "@/plugins/vuetify";
import { loadFilters } from "./filters";
import VueSocialSharing from "vue-social-sharing";
import "./app.scss";
import { ToastPlugin } from "bootstrap-vue";

Vue.config.productionTip = false;
Vue.use(LayoutPlugin);
Vue.use(VueSocialSharing);
Vue.use(loadFilters);
Vue.directive("b-toggle", VBToggle);
Vue.use(ToastPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
