// import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify, { VAutocomplete } from "vuetify/lib";

Vue.use(Vuetify, { components: { VAutocomplete } });

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});
