import moment from "moment/src/moment";

export const numberFilter = (num) => {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
    : Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const timeFilterComment = (time) => {
  return moment(time).fromNow();
};

export const ageFilter = (dob) => {
  var years = moment().diff(dob, "years");
  return years;
};
