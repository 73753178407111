<template>
  <div id="navbar">
    <div class="navbar-reader">
      <b-row class="mr-0">
        <b-col cols="2" lg="1" align-self="center">
          <b-button
            v-b-toggle.sidebar-1
            size="sm m-0 m-md-2 menu-button"
            :disabled="!userDetails.id"
          >
            <b-iconstack
              font-scale="3"
              class="rounded-circle p-2 m-md-1"
              @click="clickSideBar"
            >
              <BIconList variant="light" stacked />
              <b-icon-circle-fill
                v-if="!hasUserClicked && userDetails.id"
                variant="danger"
                shift-h="5"
                shift-v="3"
                scale="0.5"
                stacked
              />
            </b-iconstack>
          </b-button>
        </b-col>

        <b-col cols="8" md="3" lg="2" align-self="center" v-if="!searchActive">
          <img
            class="logo-img"
            :src="require('@/assets/nisharga-logo.svg')"
            alt="nisharga-logo"
          />
          <router-link class="white-link nav-name" to="/home"
            >Nisharga
          </router-link>
        </b-col>

        <b-col cols="12" md="7" lg="5" align-self="center" class="search">
          <v-autocomplete
            v-model="searchText"
            :loading="loading"
            :items="searchResult"
            item-text="title"
            item-value="id"
            :search-input.sync="searchContent"
            @input="goToPost"
            class="mx-4"
            background-color="white"
            flat
            hide-details
            label="Search"
            solo
            dense
            small-chips
            clearable
            :disabled="!this.userDetails.id"
          >
            <template v-slot:no-data> No Results found </template>
            <template v-slot:prepend-inner>
              <BIconSearch class="mr-2" />
            </template>
            <!-- <template v-slot:append>
              <b-icon icon="chevron-down" class="mr-2"> </b-icon>
            </template> -->
            <template v-slot:item="{ item }">
              <v-list-item-avatar v-if="item.type === 'Profile'" size="24">
                <img
                  v-if="item.authorImage"
                  :src="'data:image/png;base64,' + item.authorImage"
                  alt="Author Profile image"
                />
                <BIconPersonCircle v-else />
              </v-list-item-avatar>
              <BIconFileEarmarkText
                v-else
                variant="primary"
                class="h5 mr-4 ml-1"
              />
              <v-list-item-title style="text-align: left">
                {{
                  item.title.length > 50
                    ? item.title.substr(0, 50) + "..."
                    : item.title
                }}
              </v-list-item-title>
            </template>
          </v-autocomplete>
        </b-col>

        <b-col cols="2" lg="1" align-self="center">
          <b-iconstack
            font-scale="2.25"
            class="m-md-1 p-2 ml-2 notify-icon"
            id="popover-target-1"
            :disabled="!userDetails.id"
          >
            <BIconBellFill
              class="rounded-circle notification-nav"
              variant="light"
              stacked
            />
            <BIconCircleFill
              v-if="isUnreadMessage"
              variant="danger"
              shift-h="5"
              shift-v="3"
              scale="0.5"
              id="notification-popover"
              stacked
            />
          </b-iconstack>
          <b-popover
            target="popover-target-1"
            triggers="focus"
            placement="bottom"
            variant="info"
            @hide="readNotifications()"
          >
            <template #title>Notifications</template>
            <div
              v-if="notifications && notifications.length"
              ref="popoverNotification"
              class="notification-box"
              @scroll="handleScroll"
            >
              <ul
                v-for="notification in notifications"
                :key="notification._id"
                :class="{ 'unread-message': !notification.isRead }"
              >
                <li class="px-4 pt-3">
                  <a :href="`/profile/${notification.userId}`"
                    ><strong>{{ notification.userName }}</strong></a
                  >
                  {{ notification.message }}
                  <a :href="`/post/${notification.articleId}`"
                    ><strong>{{ notification.article }}</strong></a
                  >, {{ notification.time | timeFilterComment }}
                </li>
                <hr />
              </ul>
            </div>
            <span v-else>No new Notifications</span>
          </b-popover>
        </b-col>

        <b-col cols="4" lg="1" align-self="center" class="mb-md-1">
          <b-dropdown
            :text="selectedLanguage"
            variant="outline-light"
            right
            :disabled="!isFilterEnabled"
          >
            <div v-for="language in languages" :key="language">
              <b-dropdown-item @click="languageSelected(language)">{{
                language
              }}</b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>

        <b-col cols="3" lg="1" align-self="center" class="mb-md-1">
          <b-dropdown
            :text="selectedCategory"
            right
            id="dropdown-grouped"
            :disabled="!isFilterEnabled"
            variant="outline-light"
          >
            <b-dropdown-item-button @click="categorySelected('All')"
              >All
            </b-dropdown-item-button>
            <b-dropdown-group id="dropdown-group-1" header="#Stories">
              <div
                v-for="storiesCategory in storiesCategories"
                :key="storiesCategory"
              >
                <b-dropdown-item-button
                  @click="categorySelected(storiesCategory)"
                >
                  {{ storiesCategory }}
                </b-dropdown-item-button>
              </div>
            </b-dropdown-group>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-group id="dropdown-group-2" header="#General">
              <div v-for="item in generalCategories" :key="item">
                <b-dropdown-item-button @click="categorySelected(item)">
                  {{ item }}
                </b-dropdown-item-button>
              </div>
            </b-dropdown-group>
          </b-dropdown>
        </b-col>

        <b-col cols="3" lg="1" align-self="center" class="mb-md-1">
          <b-dropdown right variant="outline-light" v-if="this.userDetails.id">
            <template #button-content>
              <strong>User</strong>
            </template>
            <b-dropdown-item :href="`/profile/${userDetails.id}`"
              >Profile</b-dropdown-item
            >
            <b-dropdown-item @click="logOut">Sign Out</b-dropdown-item>
          </b-dropdown>
          <a v-else style="color: white" :href="`/login`">Login</a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment/src/moment";
import {
  LANGUAGES,
  CATEGORY_GENERAL,
  CATEGORY_STORY,
} from "../constants/general";
import {
  BButton,
  BPopover,
  BDropdown,
  BIconList,
  BIconSearch,
  BIconPersonCircle,
  BIconFileEarmarkText,
  BIconBellFill,
  BIconCircleFill,
  BIconstack,
  BDropdownItem,
  BDropdownItemButton,
  BDropdownGroup,
  BDropdownDivider,
} from "bootstrap-vue";
import { EventBus } from "../router/EventBus";

export default {
  components: {
    BButton,
    BPopover,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BDropdownGroup,
    BDropdownDivider,
    BIconList,
    BIconSearch,
    BIconPersonCircle,
    BIconFileEarmarkText,
    BIconBellFill,
    BIconCircleFill,
    BIconstack,
  },

  data() {
    return {
      generalCategories: CATEGORY_GENERAL,
      storiesCategories: CATEGORY_STORY,
      languages: LANGUAGES,
      selectedLanguage: "Language",
      selectedCategory: "Category",
      hasUserClicked: false,
      searchActive: false,
      loading: false,
      searchText: "",
      notifications: [],
      openGate: true,
      notificationLimit: 10,
      searchContent: "",
      searchResult: [],
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    isUnreadMessage() {
      return this.notifications?.some((item) => item.isRead === false);
    },

    isFilterEnabled() {
      return (
        this.$route.name === "FavoritePosts" ||
        this.$route.name === "Home" ||
        this.$route.name === "TrendingPosts"
      );
    },
  },

  watch: {
    async searchContent(val) {
      if (val?.length < 4) {
        this.searchResult = [];
        return;
      }

      if (this.loading) return;

      try {
        this.loading = true;
        let response = await this.search(val);

        let id = [];
        let authors = response.filter((item) => {
          let name = item.author.name.toUpperCase();
          if (name.includes(val.toUpperCase())) {
            if (!id.includes(item.author.id)) {
              id.push(item.author.id);
              return true;
            }
          }
        });

        authors = authors.map((item) => {
          return {
            title: item.author.name,
            id: item.author.id,
            authorImage: item.authorImage,
            type: "Profile",
          };
        });

        let articles = response.filter((item) => {
          let title = item.title.toUpperCase();
          if (title.includes(val.toUpperCase())) {
            id.push(item._id);
            return true;
          }
        });

        articles = articles.map((item) => {
          return { title: item.title, id: item._id, type: "Article" };
        });

        this.searchResult = [...authors, ...articles];
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    let lang = localStorage.getItem("languageChoice");
    this.hasUserClicked = localStorage.getItem("hasUserClicked");
    this.setCategoryAndLanguage({
      language: lang,
      shouldUpdate: this.isFilterEnabled,
    });
    this.selectedLanguage = lang && lang !== "All" ? lang : "Language";
    if (this.userDetails.id) {
      this.notifications = await this.getNotifications({
        id: this.userDetails.id,
        isUnread: true,
      });

      let res;

      if (this.notifications.length < this.notificationLimit) {
        res = await this.getNotifications({
          id: this.userDetails.id,
          limit: this.notificationLimit - this.notifications.length,
          offset: this.notifications.length,
        });
      }

      this.notifications.push(...res);
    }
  },

  methods: {
    ...mapActions([
      "setCategoryAndLanguage",
      "readAllNotifications",
      "search",
      "getNotifications",
      "signOut",
    ]),

    ...mapMutations(["setIsAuthenticated"]),

    clickSideBar() {
      if (!this.hasUserClicked) {
        this.hasUserClicked = true;
        localStorage.setItem("hasUserClicked", true);
      }
    },

    goToPost(event) {
      if (!event) return;

      let result = this.searchResult.find((item) => item.id === event);

      if (result.type === "Article") {
        this.$router.push(`/post/${event}`);
      } else {
        this.$router.push(`/profile/${event}`);
      }
    },

    async handleScroll() {
      let scrollTop = this.$refs.popoverNotification.scrollTop;
      let scrollHeight = this.$refs.popoverNotification.scrollHeight;
      let offsetHeight = this.$refs.popoverNotification.offsetHeight;
      let contentHeight = scrollHeight - offsetHeight;

      let res = [];

      if (this.openGate && contentHeight <= scrollTop) {
        this.openGate = false;
        res = await this.getNotifications({
          id: this.userDetails.id,
          limit: this.notificationLimit,
          offset: this.notifications.length,
        });

        if (res.length) this.openGate = true;
      }

      this.notifications.push(...res);
    },

    readNotifications() {
      if (this.isUnreadMessage) {
        this.readAllNotifications({ id: this.userDetails.id });
        this.notifications.forEach((item) => (item.isRead = true));
      }
    },

    logOut() {
      // this.setIsAuthenticated(false);
      this.signOut();
    },

    categorySelected(category) {
      EventBus.$emit("resetOffset");
      this.setCategoryAndLanguage({
        category,
        shouldUpdate: this.isFilterEnabled,
      });
      this.selectedCategory = category === "All" ? "Category" : category;
    },

    languageSelected(language) {
      EventBus.$emit("resetOffset");
      this.setCategoryAndLanguage({
        language,
        shouldUpdate: this.isFilterEnabled,
      });
      this.selectedLanguage = language === "All" ? "Language" : language;
    },
  },

  beforeDestroy() {
    EventBus.$destroy("resetOffset");
  },

  filters: {
    timeFilterComment(time) {
      return moment(time).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-button,
.notify-icon {
  background-color: #0d5460 !important;
  border-color: #0d5460 !important;

  &:focus,
  &:hover {
    background-color: #0d5460 !important;
    border-color: #0d5460 !important;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  }
}
// .notify-icon {
//   background-color: #0d5460 !important;
//   border-color: #0d5460 !important;
// }
.white-link:hover {
  font-weight: normal !important;
}
.nav-name {
  float: left;
  font-size: 20px;
  margin: 5px;
}
.search {
  display: inline-flex;

  &__bar {
    width: 100%;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;

    &:focus {
      width: 100%;
    }
  }
}

.navbar-reader {
  background-color: #0d5460;
  top: 0px;
  width: 100%;
  position: fixed;
  z-index: 10;
}
.sticky-navbar {
  // top: 0px;
  // width: 100%;
  // position: fixed;
  // z-index: 10;
}
.notification-nav {
  cursor: pointer;
}
.popover,
.b-popover,
.bs-popover-top {
  min-width: 20rem !important;
}
.notification-box {
  max-height: 20rem !important;
  overflow-y: auto;
  border: 1px solid green;
  background-color: white;

  a {
    color: #544a71 !important;
  }

  hr,
  ul {
    margin-bottom: 0;
  }
}
.unread-message {
  background-color: #dde2ea;
}

ul.search-list {
  list-style-type: square;
}
.logo-img {
  float: left;
  margin-left: -30px;
  margin-right: 0.5rem;
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 767px) {
  .logo-img {
    margin-left: 0;
  }
}
</style>
