export const LANGUAGES = [
  "All",
  "Bangla",
  "English",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Tamil",
  "Telugu",
  "Urdu",
];

export const CATEGORY_GENERAL = [
  "Article",
  "Blog",
  "Jokes",
  "Poem",
  "Recipe",
  "Sports",
  "Travel",
];

export const CATEGORY_STORY = [
  "Educational",
  "Horror",
  "Romance",
  "Short stories",
  "Suspense",
  "Thriller",
];
