import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { authHeader } from "../helpers/auth-header";
import router from "../router";
import { EventBus } from "../router/EventBus";

Vue.use(Vuex);

// const baseApi = "http://localhost:8443";
const baseApi = "https://www.nisharga.com/api";
// const baseApi = "http://192.168.0.104:8443";
// const baseApi = process.env.VUE_APP_API_BASE_URL;

const generateQuery = ({
  userId,
  language,
  category,
  savedQuery,
  limit = 10,
  offset = 0,
}) => {
  const query = {
    ...savedQuery,
    ...(userId && { "author.id": userId }),
    ...(language && { language }),
    ...(category && { category }),
    limit,
    offset,
  };

  for (let key in query) {
    if (!query[key] || query[key] === "All") {
      delete query[key];
    }
  }

  return query;
};

export default new Vuex.Store({
  state: {
    articleCollection: [],
    favoritePostList: [],
    bookmarkedLists: [],
    userDetails: {},
    isUserAuthenticated: true,
    queryParams: {},
    categoryAndLanguage: "",
    currentArticle: {},
    trendingPosts: {},
  },

  getters: {
    articleCollection: (state) => state.articleCollection,
    bookmarkedLists: (state) => state.bookmarkedLists,
    userDetails: (state) => state.userDetails,
    favoritePostList: (state) => state.favoritePostList,
    userId: (state) => state.userDetails.id,
    isUserAuthenticated: (state) => state.isUserAuthenticated,
    queryParams: (state) => state.queryParams,
    categoryAndLanguage: (state) => state.categoryAndLanguage,
    currentArticle: (state) => state.currentArticle,
    trendingPosts: (state) => state.trendingPosts,
  },

  mutations: {
    setArticleCollection(state, { data, shouldAdd }) {
      if (shouldAdd) {
        state.articleCollection.push(...data);
      } else {
        state.articleCollection = data;
      }
    },
    setFavoriteList(state, data) {
      state.favoritePostList = data;
    },
    setUserDetails(state, data) {
      state.userDetails = data;
    },
    setBookmarkList(state, collection) {
      state.bookmarkedLists = [...collection];
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isUserAuthenticated = isAuthenticated;
    },
    setQueryParams(state, queryParams) {
      state.queryParams = { ...queryParams };
    },
    setCategoryAndLanguage(state, { language, category }) {
      state.categoryAndLanguage = { language, category };
    },
    setCurrentArticle(state, currentArticle) {
      state.currentArticle = currentArticle;
    },
    setTrendingPosts(state, trendingPosts) {
      state.trendingPosts = { ...trendingPosts };
    },
    removeDeletedPost(state, articleId) {
      let index = state.articleCollection.findIndex(
        (item) => item._id === articleId
      );
      if (~index) {
        state.articleCollection.splice(index, 1);
      }
    },
    deleteSavedWriting(state, data) {
      let index = state.userDetails.savedPost.findIndex(
        (item) => item._id === data
      );
      if (~index) {
        state.userDetails.savedPost.splice(index, 1);
      }
    },
    updateSavedWriting(state, data) {
      let index = state.userDetails.savedPost.findIndex(
        (item) => item._id === data._id
      );
      if (~index) {
        state.userDetails.savedPost[index] = data;
      } else {
        state.userDetails.savedPost.push(data);
      }
    },
  },

  actions: {
    async registerUser(_, { form }) {
      const response = await axios.post(`${baseApi}/user/register`, form);

      return response.data;
    },

    async forgotPassword(_, email) {
      const response = await axios.post(
        `${baseApi}/user/password-reset`,
        email
      );

      return response.data;
    },

    async forgotUserName(_, email) {
      const response = await axios.post(
        `${baseApi}/user/forgot-username`,
        email
      );

      return response.data;
    },

    async resetPassword(_, form) {
      const response = await axios.post(
        `${baseApi}/user/password-change`,
        form
      );

      return response.data;
    },

    async signIn({ commit }, { username, password, otp }) {
      const response = await axios.post(`${baseApi}/user/authenticate`, {
        username,
        password,
        otp,
      });

      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      commit("setUserDetails", response.data);
      commit("setBookmarkList", response.data.bookmarks);

      return true;
    },

    async deleteUserProfile(_, { username, password }) {
      await axios.delete(`${baseApi}/user/deleteUser`, {
        data: { username, password },
        headers: authHeader(),
      });

      return true;
    },

    async requestOTP(_, username) {
      const response = await axios.post(
        `${baseApi}/user/generate-otp`,
        username
      );

      return response.data;
    },

    signOut({ commit }) {
      // remove user from local storage to log user out
      commit("setUserDetails", {});
      localStorage.removeItem("user");
      router.push("/login");
      EventBus.$emit("signout");
    },

    setCategoryAndLanguage(
      { commit, dispatch, getters },
      { category, language, shouldUpdate }
    ) {
      let queryParams = { ...getters.queryParams };
      queryParams = {
        ...queryParams,
        userId: queryParams["author.id"],
        ...(language && { language }),
        ...(category && { category }),
        offset: 0,
      };

      if (language) localStorage.setItem("languageChoice", language);

      commit("setCategoryAndLanguage", queryParams);

      if (!shouldUpdate) {
        return;
      }

      dispatch("fetchAllArticles", { ...queryParams });
      dispatch("getTredingPosts");
    },

    async fetchArticlesByAuthor(_, dataProps) {
      const response = await axios.get(
        `${baseApi}/articles/author/${dataProps.authorId}`,
        {
          headers: authHeader(),
          body: {
            useCredentails: true,
          },
        }
      );

      return response.data;
    },

    async fetchAllArticles({ commit, getters }, dataProps) {
      const query = generateQuery({
        ...dataProps,
        savedQuery: getters.categoryAndLanguage,
      });

      const queryParams = new URLSearchParams(query).toString();

      const response = await axios.get(`${baseApi}/articles?${queryParams}`, {
        headers: authHeader(),
        body: {
          useCredentails: true,
        },
      });

      commit("setArticleCollection", {
        data: response.data,
        shouldAdd: dataProps.shouldAdd,
      });
      commit("setQueryParams", query);

      return response.data;
    },

    async fetchArticle(_, { id }) {
      const response = await axios.get(`${baseApi}/articles/${id}`, {
        headers: authHeader(),
      });

      return response.data;
    },

    async getAllParts(_, { id }) {
      const response = await axios.get(`${baseApi}/articles/allParts/${id}`, {
        headers: authHeader(),
      });

      return response.data;
    },

    async postArticle(_, { payload }) {
      const response = await axios.post(`${baseApi}/articles`, payload, {
        headers: authHeader(),
      });

      return response.data;
    },

    async editArticle(_, { articleId, payload }) {
      const response = await axios.put(
        `${baseApi}/articles/${articleId}`,
        payload,
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async deletePost({ commit }, { articleId, userId }) {
      const response = await axios.delete(`${baseApi}/articles/${articleId}`, {
        data: { userId },
        headers: authHeader(),
      });

      commit("removeDeletedPost", articleId);

      return response.data;
    },

    async getAllComments(_, { articleId, userId }) {
      const response = await axios.get(
        `${baseApi}/articles/comments/${articleId}`,
        {
          data: { userId },
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async saveProfileData(_, { id, profileData }) {
      const response = await axios.patch(
        `${baseApi}/user/editProfile/${id}`,
        profileData,
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async saveComment(_, { id, comment }) {
      const response = await axios.put(
        `${baseApi}/articles/comment/${id}`,
        comment,
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async deleteMyComment(_, { articleId, userId, commentId }) {
      const response = await axios.delete(
        `${baseApi}/articles/comment/${articleId}`,
        { data: { userId, commentId }, headers: authHeader() }
      );

      return response.data;
    },

    async editMyComment(_, { articleId, userId, commentId, comment }) {
      const response = await axios.patch(
        `${baseApi}/articles/comment/${articleId}`,
        {
          userId,
          commentId,
          comment,
        },
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async getTredingPosts({ getters, commit }) {
      let today = new Date();
      let weekDays = today.getDay() + 1;
      let monthDays = today.getDate();
      let lang = getters.categoryAndLanguage.language;

      let value = await Promise.all([
        axios.get(
          `${baseApi}/articles/trending/now/${
            lang && lang !== "All" ? `?lang=${lang}` : ""
          }`,
          {
            headers: authHeader(),
          }
        ),
        axios.get(
          `${baseApi}/articles/trending/now/1${
            lang && lang !== "All" ? `?lang=${lang}` : ""
          }`,
          {
            headers: authHeader(),
          }
        ),
        axios.get(
          `${baseApi}/articles/trending/now/${weekDays}${
            lang && lang !== "All" ? `?lang=${lang}` : ""
          }`,
          {
            headers: authHeader(),
          }
        ),
        axios.get(
          `${baseApi}/articles/trending/now/${monthDays}${
            lang && lang !== "All" ? `?lang=${lang}` : ""
          }`,
          {
            headers: authHeader(),
          }
        ),
      ]);

      let trendingPost = {
        allTime: value[0].data,
        daily: value[1].data,
        weekly: value[2].data,
        monthly: value[3].data,
        isDataFetched: true,
      };

      commit("setTrendingPosts", trendingPost);
    },

    // async getTredingPosts({ getters }, range) {
    //   let lang = getters.categoryAndLanguage.language;
    //   const response = await axios.get(
    //     `${baseApi}/articles/trending/now/${range ? range : ""}${
    //       lang ? `?lang=${lang}` : ""
    //     }`,
    //     {
    //       headers: authHeader(),
    //     }
    //   );

    //   return response.data;
    // },

    async likePost(_, { id, userId }) {
      const response = await axios.patch(
        `${baseApi}/articles/like/${id}`,
        { userId },
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async getUserDetails({ commit }, { userId }) {
      const response = await axios.get(`${baseApi}/user/${userId}`, {
        headers: authHeader(),
      });

      commit("setUserDetails", response.data);
      commit("setBookmarkList", response.data.bookmarks);
    },

    async bookmarkPost({ commit }, { userId, postId }) {
      const response = await axios.patch(
        `${baseApi}/user/${userId}/bookmark`,
        { postId },
        {
          headers: authHeader(),
        }
      );

      commit("setBookmarkList", response.data.bookmarks);
    },

    async getFavoritePostList({ commit }, { bookmarks }) {
      const response = await axios.post(
        `${baseApi}/articles/favorites`,
        { bookmarks },
        {
          headers: authHeader(),
        }
      );

      commit("setFavoriteList", response.data);
    },

    async getLikedPeopleList(_, { userList }) {
      const response = await axios.post(
        `${baseApi}/user/likedlist`,
        { userList },
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async getMyFollowList(_, { authorList }) {
      const response = await axios.post(
        `${baseApi}/user/myAuthors`,
        { authorList },
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async getAuthorProfile(_, { authorId }) {
      const response = await axios.get(
        `${baseApi}/user/myAuthors/${authorId}`,
        { headers: authHeader() }
      );

      return response.data;
    },

    async followAuthor(_, { payload }) {
      const response = await axios.patch(`${baseApi}/user/follow`, payload, {
        headers: authHeader(),
      });

      return response.data;
    },

    async glorifyUser(_, { id, userId }) {
      const response = await axios.patch(
        `${baseApi}/user/glorify/${id}`,
        { userId },
        {
          headers: authHeader(),
        }
      );

      return response.data;
    },

    async getTredingAuthors() {
      const response = await axios.get(`${baseApi}/user/trending/now`, {
        headers: authHeader(),
      });

      return response.data;
    },

    async search(_, text) {
      const response = await axios.get(`${baseApi}/articles/search/${text}`, {
        headers: authHeader(),
      });

      return response.data;
    },

    async reportpost(_, payload) {
      const response = await axios.post(`${baseApi}/report`, payload, {
        headers: authHeader(),
      });

      return response.data;
    },

    async getNotifications(_, { id, limit, offset, isUnread }) {
      const query = {
        ...(isUnread && { isUnread }),
        ...(limit && { limit }),
        ...(offset && { offset }),
      };

      const queryParams = new URLSearchParams(query).toString();

      const response = await axios.get(
        `${baseApi}/notifications/${id}?${queryParams}`,
        { headers: authHeader() }
      );

      return response.data;
    },

    async sendNotification(_, { payload }) {
      await axios.post(
        `${baseApi}/notifications/${payload.id}/notify`,
        payload,
        {
          headers: authHeader(),
        }
      );
    },

    async saveArticle({ commit }, { id, payload }) {
      const response = await axios.patch(
        `${baseApi}/user/${id}/savePost`,
        payload,
        {
          headers: authHeader(),
        }
      );

      commit("updateSavedWriting", response.data);

      return response.data;
    },

    async deletesavedArticle({ commit }, { id, postId }) {
      const response = await axios.patch(
        `${baseApi}/user/${id}/deleteDraft`,
        {
          postId,
        },
        {
          headers: authHeader(),
        }
      );

      commit("deleteSavedWriting", postId);

      return response.data;
    },

    async notifyAll(_, { payload }) {
      await axios.post(`${baseApi}/notifications/notify/all`, payload, {
        headers: authHeader(),
      });
    },

    async readAllNotifications(_, { id }) {
      await axios.put(
        `${baseApi}/notifications/${id}/readNotifications`,
        {},
        {
          headers: authHeader(),
        }
      );
    },

    async editProfileImage(_, { userId, image }) {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("size", image.size);
      formData.append("name", image.name);

      await axios.patch(`${baseApi}/user/${userId}/image`, formData, {
        headers: { "Content-Type": "multipart/form-data", ...authHeader() },
      });
    },
  },
});
